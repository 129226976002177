const enGB = {
  signIn: 'Sign in',
  signUp: 'Sign up',
  signOut: 'Sign out',
  logging: 'Log in',
  returnToHome: 'Return to homepage',
  char_one: 'character',
  char_other: 'characters',
  cancel: 'Cancel',
  confirm: 'Confirm',
  homePage: 'Homepage',
  noData: 'No data',
  pageNotFound: 'Page not found.',
  intlDisplayNames: '{{value, displayNames}}',
  intlList: '{{value, list}}',
  intlDateTime: '{{value, datetime}}',
  intlDateTimeRange: '{{value, dateTimeRange}}',
  loginAs: 'Logged in as:',
  yourId: 'Your identifier:',
  websiteName: 'Candidate Portal',
  addRecruitment: 'Add recruitment stage',

  genders: {
    female: 'Female',
    male: 'Male',
    other: 'Other',
    notStated: 'Not stated',
  },

  aria: {
    userMenu: 'user menu',
    languageButton: 'open language menu',
    back: 'back',
    togglePassword: 'toggle password visibility',
    hamburger: 'open navigation menu',
    close: 'close',
    edit: 'edit',
    delete: 'delete',
    show: 'show',
    clear: 'clear',
  },

  form: {
    save: 'Save',
    required: 'This field is required',
    noWhitespace: 'The field cannot be empty',
    pattern: 'Please enter a valid {{what}}',
    betweenLen: 'The text needs to be between {{min}} and {{max}} $t(char, {"count": {{max}} }) long',
    minLen: 'At least {{value}} $t(char, {"count": {{value}} })',
    maxLen: 'No more than {{value}} $t(char, {"count": {{value}} })',
    min: 'Value cannot be less than {{value}}',
    max: 'Value cannot be greater than {{value}}',
    unicodeLettersAndSpaces: 'Only letters and spaces',
    telephone: 'Incorrect phone number',
    email: 'Incorrect email address',
    password: 'Invalid password',
    passwordMatch: 'Passwords should match',
    personName: 'Incorrect characters',
    dialCodeRequired: 'Enter dial code',
    ReCAPTCHAValidationFaild: 'ReCAPTCHA Validation Faild',
    win1250only: 'Field contains illegal characters',
  },

  apiErrors: {
    default: 'Something went wrong, please try again.',
    unauthorized: 'Your session has expired, please log in again.',
    forbidden: 'Access to this resource on the server is denied.',
  },

  passwordHints: {
    passwordShouldHas: 'The password should have:',
    charLength: 'between {{min}} and {{max}} $t(char, {"count": {{max}} })',
    lowercaseLetter: 'one lower case letter',
    capitalLetter: 'one capital letter',
    digit: 'one digit',
    specialSign: 'one special character, e.g. @#!$%',
    confirmPasswordError: 'The passwords do not match',
  },

  indexPage: {
    defaultTitle: '',
    firstLineTitle: 'Enrolment of applicants',
    personalData: 'Personal data',
    photo: 'ID photo',
    messages: 'Announcements',
    accountActivation: 'Send application',
  },

  authRoutes: {
    returnToSignIn: 'Return to login page',
  },

  loginPage: {
    emailAddress: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    forgotPassword: 'Forgot password?',
    dontHaveAccount: "Don't have an account? Sign up",
    alreadyHaveAccount: 'Already have an account?',
    login: 'Sign in',
    rememberMe: 'Remember me',
  },

  register: {
    registration: 'Register',
    submitEmail: 'Email me a activation link',
    agreement1:
      'I confirm that I have read and understood the rules of use of the electronic registration on the Candidate Portal of Lodz University of Technology.',
    agreement2:
      'I confirm that I have read the purpose and legal basis for the processing of personal data and information about the personal data administrator. ',
    agreement3:
      'I am aware of the fact that I bear full legal responsibility and all consequences, including removal from the list of those admitted, for providing incorrect data needed in the admission procedure for studies at Lodz University of Technology.',
    agreement4: 'I agree to receive information electronically.',
    agreement5:
      'I agree that Lodz University of Technology may confirm the authenticity of the documents attached during the admission process concerning the course of my education in the units or institutions that issued them in order to confirm the accuracy of the facts declared by me during the admission process.',
    agreement6: 'I agree to receive information related to promotional activities of Lodz University of Technology by e-mail.',
    link1: 'Rules',
    link2: 'GDPR',
    checkAll: 'Select all consents',
  },

  emailConfirmationPage: {
    resendEmail: 'Resend email',
    emailSent: 'The email has been resent.',
    accountRegistered:
      'Your account has been registered. You will receive a link to confirm your account to the email address you provided during registration.',
  },

  accountVerificationPage: {
    title: 'Account activation',
    submitButton: 'Send request',
    successfulActivation: 'Your email has been confirmed.',
    errorMessage: 'The email address could not be verified.',
    waitForVerification: 'Email address verification in progress',
  },

  recoverPasswordPage: {
    recoverPassword: 'Recover password',
    emailSent: 'An email has been sent. To change your password please click on the link in the body of the message.',
    submitEmail: 'Email me a recovery link',
  },

  resetPasswordPage: {
    resetPassword: 'Change password',
    savePassword: 'Change password',
    passwordChanged: 'Your password has been changed, you can now log in with your new credentials.',
  },
  footer: {
    universityName: 'Admissions Section at Lodz University of Technology',
    universityAddress: 'Radwańska Street 29, building A13',
    universityAddressCaption: '(additional entrance from 22 Stefanowskiego Street)',
    recruitmentWebsite: 'Admissions website',
    contact1: 'Contact for Polish nationals – first and second cycle studies',
    contact2: 'Contact for foreigners - first and second cycle',
    contact3: 'Contact for candidates – third cycle',
    stepByStep: 'Admissions STEP by STEP',
    rules: 'Admission Rules and Regulations',
    gdpr: 'GDPR',
  },
  backendErrors: {
    'RC-1001': 'Unexpected error',
    'RC-1101': 'Invalid auth token',
    'RC-1102': 'Access forbidden',
    'RC-1103': 'Bad credentials',
    'RC-1201': 'Malformed request',
    'RC-1202': 'Incomplete request',
    'RC-1301': 'Field validation error',
    'RC-1302': 'Fields relationship validation error',
    'RC-1402': 'Resource unmodifiable',
    'RC-1403': 'Resource already exists',
    'RC-1404': 'Resource not found',
    'RC-1501': 'Webservice response processing error',
    'RC-1502': 'Webservice request error',
    'RC-1601': 'Data persistence error',
    'RC-1602': 'Data persistence integrity error',
    unknownError: 'Unknown Error',
    unknownErrorDetails: 'Please try again or contact the administrator',
  },
  successResponse: {
    defaultSuccess: 'Success',
    defaultMessage: 'The operation was completed successfully',
    saveSuccess: 'Success',
    saveMessage: 'Data saved correctly',
    updateSuccess: 'Success',
    updateMessage: 'Data has been updated correctly',
    deleteSuccess: 'Success',
    deleteMessage: 'Data deleted correctly',
  },
  personalData: {
    pesel: 'PESEL number',
    name: 'Name',
    secondName: 'Second name',
    surname: 'Last name',
    familyName: "Candidate's family name",
    fatherName: "Father's name",
    motherName: "Mother's name",
    motherFamilyName: "Mother's maiden name",
    gender: 'Sex',
    genderSelect: {
      male: 'Male',
      female: 'Female',
    },
    hasPesel: 'I hold a PESEL number',
    passportNumber: 'Passport series and number',
    passportCountry: 'Name of the country issuing the passport',
    nationality: 'Nationality',
    citizenshipPrimary: 'Citizenship',
    citizenshipSecondary: 'Second citizenship',
    citizenshipAdditionalInfo: 'Choose the one that best describes your current situation',
    citizenshipAdditionalInfoSelect: {
      s1: 'Posiadam ważną Kartę Polaka lub decyzję w sprawie stwierdzenia polskiego pochodzenia wydaną przez Konsula RP',
      s2: 'Jestem stypendystą NAWA / Ubiegam się o stypendium NAWA',
      s3: 'Posiadam zezwolenie na pobyt stały lub jestem rezydentem długoterminowym Unii Europejskiej',
      s4: 'Posiadam status uchodźcy/korzystam z ochrony czasowej/ochrony uzupełniającej na terytorium RP',
      s5: 'Jestem obywatelem Unii Europejskiej, Konfederacji Szwajcarskiej lub EFTA',
      s6: 'Posiadam Certyfikat znajomości języka polskiego na poziomie C1 wydany w Polsce przez Komisję Państwową',
      s7: 'Jestem cudzoziemcem będącym małżonkiem, wstępnym (rodzicem) lub zstępnym (dzieckiem) obywatela RP, mieszkającym na terytorium RP',
      s8: 'Posiadam zezwolenie na pobyt czasowy wydane w celu prowadzenia badań naukowych lub w związku z innymi okolicznościami',
      s9: 'Pozostali cudzoziemcy niespełniający powyższych warunków',
    },
    birthDate: 'Date of birth',
    birthCountry: 'Country od birth',
    birthPlace: 'Place of birth',
    firstPersonInFamilyToStudy: 'Are you the first person in your immediate family who wants to study?',
    contactAddress: 'Contact address',
    registeredAddress: 'Residential Adress',
    otherMailingAddress: 'Other mailing address',
    healthInformation: 'Additional health information',
    certificateOfDisability: 'I have a disability certificate or equivalent document',
    personWithSpecialNeeds: 'I am a person with special needs (without a certificate, but with a disability)',
    country: 'Country',
    city: 'Locality',
    voivodeship: 'Voivodeship',
    postalCode: 'Postal code',
    street: 'Street or town name',
    streetNumber: 'House number',
    apartmentNumber: 'Apartment number',
    phone: 'Phone number',
    typeOfOrigin: 'Type of origin - town/city',
    typeOfOriginSelect: {
      city: 'city',
      village: 'town',
    },
  },
} as const;

Object.freeze(enGB);

export default enGB;
