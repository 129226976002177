/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouterProvider } from 'react-router/dom';
import { createBrowserRouter, type DataStrategyFunction } from 'react-router';
import { LoadingOverlay } from '@/components/LoadingOverlay';
import { NoMatch } from '@/routes/NoMatch';
import { SUB_ROUTES } from '@/settings/router';

const dependenciesToInject = { authProvider: null };
Object.freeze(dependenciesToInject);

export type IContext = typeof dependenciesToInject;

const dataStrategy: DataStrategyFunction = async ({ matches }) => {
  // Run loaders in parallel with the `context` value
  const matchesToLoad = matches.filter((m) => m.shouldLoad);
  const results = await Promise.all(
    matchesToLoad.map((match) =>
      match.resolve((handler) => {
        return handler(dependenciesToInject);
      })
    )
  );
  return results.reduce(
    (acc, result, i) =>
      Object.assign(acc, {
        [matchesToLoad![i]!.route.id]: result,
      }),
    {}
  );
};

const router = createBrowserRouter(
  [
    {
      hydrateFallbackElement: <LoadingOverlay isLoading />,
      lazy: () => import('app/routes/Authenticated') as any,
      children: [
        {
          index: true,
          lazy: () => import('@/routes/Index') as any,
        },
        {
          lazy: () => import('@/routes/SubpageLayout'),
          children: [
            {
              path: SUB_ROUTES.PERSONAL_DATA,
              lazy: () => import('@/routes/PersonalData') as any,
            },
          ],
        },
      ],
    },
    {
      hydrateFallbackElement: <LoadingOverlay isLoading />,
      lazy: () => import('@/routes/Unauthenticated'),
      children: [
        {
          path: SUB_ROUTES.AUTH,
          lazy: () => import('@/routes/Auth') as any,
        },
        {
          path: SUB_ROUTES.RESET_PASSWORD_REQUEST,
          lazy: () => import('@/routes/ResetPasswordRequest') as any,
        },
        {
          path: SUB_ROUTES.RESET_PASSWORD_REQUEST_SUCCESS,
          lazy: () => import('@/routes/ResetPasswordRequestSuccess') as any,
        },
        {
          path: SUB_ROUTES.RESET_PASSWORD,
          lazy: () => import('@/routes/ResetPassword') as any,
        },
        {
          path: SUB_ROUTES.RESET_PASSWORD_SUCCESS,
          lazy: () => import('@/routes/ResetPasswordSuccess') as any,
        },
        {
          path: SUB_ROUTES.REGISTER,
          lazy: () => import('@/routes/Register') as any,
        },
        {
          path: SUB_ROUTES.ACCOUNT_ACTIVATION,
          lazy: () => import('@/routes/AccountActivation') as any,
        },
        {
          path: SUB_ROUTES.REGISTER_SUCCESS,
          lazy: () => import('@/routes/RegisterSuccess') as any,
        },
        {
          path: SUB_ROUTES.ACCOUNT_ACTIVATION_SUCCESS,
          lazy: () => import('@/routes/AccountActivationSuccess') as any,
        },
      ],
    },
    {
      path: '*',
      Component: NoMatch,
    },
  ],
  {
    dataStrategy,
  }
);

export const Router = () => <RouterProvider router={router} />;
