export interface ResponseErrorInterface {
  type: string;
  code: string;
  originCode: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
  method: string;
  fields?: [];
  timestamp: string;
}

export function isInstanceOfResponseErrorInterface(err: unknown): boolean {
  //todo: Zmienić na nowszy zapis z operatorem "IN"
  if (err instanceof Object) {
    return (
      Object.prototype.hasOwnProperty.call(err, 'type') &&
      Object.prototype.hasOwnProperty.call(err, 'title') &&
      Object.prototype.hasOwnProperty.call(err, 'status') &&
      Object.prototype.hasOwnProperty.call(err, 'detail') &&
      Object.prototype.hasOwnProperty.call(err, 'instance') &&
      Object.prototype.hasOwnProperty.call(err, 'timestamp') &&
      Object.prototype.hasOwnProperty.call(err, 'originCode') &&
      Object.prototype.hasOwnProperty.call(err, 'method') &&
      Object.prototype.hasOwnProperty.call(err, 'code')
    );
  }
  return false;
}
