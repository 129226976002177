import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from '@mui/material';
import { HomePageLinkBtn } from '@/components/Link';
import { HomeLogo } from '@/components/HomeLogo';
import { routes } from '@/settings/router';
import Grid from '@mui/material/Grid2';

export const NoMatch = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100dvh' }}>
      <Box sx={{ textAlign: 'center' }}>
        <HomeLogo to={routes.index()} sx={{ display: 'block', fontSize: '0', marginBottom: '2rem' }} />
        <Grid
          component={Card}
          size={{ xs: 12, md: 4 }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: { md: '600px' },
            gap: 2,
            p: '3rem',
            pt: '1rem',
            textAlign: 'center',
          }}
        >
          <Typography component='h1' fontSize={100} color='primary'>
            404
          </Typography>
          <Typography component='p' variant='h4'>
            {t('pageNotFound')}
          </Typography>
          <HomePageLinkBtn color='primary' sx={{ mt: 3 }} />
        </Grid>
      </Box>
    </Box>
  );
};
