import type { ReactNode } from 'react';
import { Stack, type StackProps } from '@mui/material';
import { t } from 'i18next';
import { ErrorContent } from './ErrorContent';
import { parseError } from '@/utilities/parsers/errorParser';

interface ErrorPageProps extends StackProps {
  error: unknown;
  children?: ReactNode;
}

export const ErrorPage = (props: ErrorPageProps) => {
  const { children, error, sx, ...restProps } = props;

  const { errorCodes, message, date } = parseError(error);

  // @ts-expect-error(Expected error from i18next)
  let messageTranslation: string = errorCodes && errorCodes !== '' ? t('backendErrors.' + errorCodes) : message;
  // @ts-expect-error(Expected error from i18next)
  messageTranslation = messageTranslation === 'unknownError' ? t('backendErrors.' + messageTranslation) : messageTranslation;

  return (
    <Stack sx={[{ gap: 4, alignItems: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]} {...restProps}>
      <ErrorContent message={messageTranslation} title={errorCodes} date={date} />
      {children}
    </Stack>
  );
};
