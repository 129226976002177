import { isInstanceOfResponseErrorInterface, type ResponseErrorInterface } from '@/utilities/requestUtils/ResponseErrorInterface';

interface UserErrorInterface {
  errorCodes: string;
  message: string;
  detail?: string;
  date: string;
}

export const parseError = (err: unknown): UserErrorInterface => {
  if (isInstanceOfResponseErrorInterface(err)) {
    const error = err as unknown as ResponseErrorInterface;

    return {
      errorCodes: error.code,
      message: error.title,
      detail: error.detail,
      date: getCurrentDate(error.timestamp),
    };
  } else if (
    Object.prototype.hasOwnProperty.call(err, 'errorCodes') &&
    Object.prototype.hasOwnProperty.call(err, 'message') &&
    Object.prototype.hasOwnProperty.call(err, 'date')
  ) {
    return err as UserErrorInterface;
  }
  // else if (Object.prototype.hasOwnProperty.call(err, 'message')) {
  //   // @ts-expect-error(expect-error)
  //   const message = err.message;
  //   return {
  //     errorCodes: '',
  //     message: message,
  //     detail: '',
  //     date: getCurrentDate(),
  //   };
  // }
  else if (err && typeof err === 'object' && 'errors' in err) {
    // err['errors']['detail'] = '';
    return err['errors'] as UserErrorInterface;
  } else {
    return {
      errorCodes: '',
      message: 'unknownError',
      detail: 'unknownErrorDetails',
      date: getCurrentDate(),
    };
  }

  // isRouteErrorResponse();
};

const getCurrentDate = (timestamp?: string) => {
  const dateTime = timestamp ? new Date(timestamp) : new Date();
  const formatter = new Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return formatter.format(dateTime);
};
