import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import { Link, type LinkProps } from '@/components/Link';
import Logo from './logotyp.png';

export interface HomeLogoProps extends Omit<LinkProps, 'variant'> {
  variant?: 'text' | 'icon';
}

export const HomeLogo = (props: HomeLogoProps): JSX.Element => {
  const { variant = 'text', ...linkProps } = props;

  const { t } = useTranslation();

  let content = <Box component='img' src={Logo} alt='Logo' sx={{ maxHeight: '100px;' }} />;

  if (variant === 'icon') {
    content = <HomeIcon sx={{ verticalAlign: 'inherit', fontSize: 'inherit', color: 'inherit' }} />;
  }

  return (
    <Link underline='none' color='inherit' {...linkProps}>
      <Tooltip title={t('returnToHome')}>{content}</Tooltip>
    </Link>
  );
};
