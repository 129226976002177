export const ROUTE_PARAMS = {
  ID: 'id',
} as const;

export const SUB_ROUTES = {
  PERSONAL_DATA: 'personal-data',
  AUTH: 'auth',
  RESET_PASSWORD_REQUEST: 'reset-password-request',
  RESET_PASSWORD_REQUEST_SUCCESS: 'reset-password-request-success',
  RESET_PASSWORD: 'user/password',
  RESET_PASSWORD_SUCCESS: 'reset-password-success',
  REGISTER: 'register',
  REGISTER_SUCCESS: 'register-success',
  ACCOUNT_ACTIVATION: 'user/account',
  ACCOUNT_ACTIVATION_SUCCESS: 'account-activation-success',
} as const;

export const routes = {
  index: () => '/' as const,

  personalData: () => `${routes.index()}${SUB_ROUTES.PERSONAL_DATA}` as const,

  auth: () => `${routes.index()}${SUB_ROUTES.AUTH}` as const,
  resetPasswordRequest: () => `${routes.index()}${SUB_ROUTES.RESET_PASSWORD_REQUEST}` as const,
  resetPasswordRequestSuccess: () => `${routes.index()}${SUB_ROUTES.RESET_PASSWORD_REQUEST_SUCCESS}` as const,
  resetPassword: () => `${routes.index()}${SUB_ROUTES.RESET_PASSWORD}` as const,
  resetPasswordSuccess: () => `${routes.index()}${SUB_ROUTES.RESET_PASSWORD_SUCCESS}` as const,

  register: () => `${routes.index()}${SUB_ROUTES.REGISTER}` as const,
  registerSuccess: () => `${routes.index()}${SUB_ROUTES.REGISTER_SUCCESS}` as const,
  accountActivation: () => `${routes.index()}${SUB_ROUTES.ACCOUNT_ACTIVATION}` as const,
  accountActivationSuccess: () => `${routes.index()}${SUB_ROUTES.ACCOUNT_ACTIVATION_SUCCESS}` as const,
} as const;
